import { faBook, faBuilding, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import styled, { css } from 'styled-components';

import { IItem } from '../../../../entities/IFilters';
import { EFilterType, EProfileType } from '../../../../entities/IGlobal';
import { colorStack } from '../../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../../styleHelpers/fontSizes';
import { Avatar, EAvatarSize } from '../../Avatar/Avatar';
import { Button, EButtonTypeSchema } from '../../Buttons/NewButton';
import { ESpinnerSize, Spinner } from '../../Spinner/Spinner';
import { MultilineTextWithEllipsis } from '../../TextWithEllipsis/MultilineTextWithEllipsis';
import { EButtonsTypes } from '../CorePicker';
import IntlMessage from '../../IntlMessage';

const Wrapper = styled.div`
    border-radius: 8px;
    margin-top: 4px;
    overflow: hidden;
    background: ${colorStack.white};
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
    > div {
        > div:last-child {
            right: 4px!important;
        }
    }
`;

const NoResult = styled.div`
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${fontSizeAndHeight[16]};
    color: ${colorStack.content};
`;

const SingleElem = styled.div<{ active?: boolean }>`
    font-weight: ${({ active }) => active ? 500 : 400};
    padding: 12px 1rem;
    min-height: 46px;
    justify-content: space-between;
    background: ${colorStack.white};
    transition: all .3s;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    &:hover {
        background: ${colorStack.lightBlue};
    }
    ${props => props.active && css`
        background: ${colorStack.lightBlue};
    `}
    > div:first-child {
        width: calc(100% - .5rem - 34px);
    }
    > div:nth-child(2) {
        width: calc(100% - 35px);
    }
`;

const ShadowLayer = styled.div`
    width: 97%!important;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
`;

const AddElem = styled.div`
    padding: 12px 1rem;
    height: 56px;
    background: ${colorStack.bodyBg};
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 1rem;
    button {
        margin: 0 0 0 auto;
    }
`;

const Icon = styled.div`
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 100%;
    background: ${colorStack.lightBlue};
    font-size: 1rem;
`;

const Texts = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 56px - 100px);
    > span {
        ${fontSizeAndHeight[16]};
        font-weight: 600;
    }
    > span:nth-of-type(2) {
        font-weight: 400;
    }
    > div {
        ${fontSizeAndHeight[16]};
    }
`;

interface IProps {
    items: IItem[];
    selectedItems: IItem[];
    type: EFilterType;
    inputText: string;
    multiSelect?: boolean;
    buttonsType?: EButtonsTypes;
    loading?: boolean;
    hideButtons?: boolean;
    onSelectItem(item: IItem);
    userFormHandler();
    companyFormHandler();
    themeFormHandler();
    areaOfResponsibilityFormHandler();
}

export const SearchResults: FC<React.PropsWithChildren<IProps>> = props => {

    const selectItem = (elem: IItem) => {
        props.onSelectItem(elem);
    };

    const selectShadowItem = (item: IItem, e?: React.MouseEvent<HTMLDivElement>) => {
        e?.stopPropagation();
        e?.preventDefault();
        selectItem(item);
    };

    return (
        <Wrapper>
            <Scrollbars autoHeight autoHeightMax={192}>
                {
                    <>
                        {props.loading ? (
                            <>
                                <br />
                                <Spinner size={ESpinnerSize.SMALL} />
                                <br />
                            </>
                        ) : (
                            <div>
                                {props.items?.length > 0 ? (
                                    <>
                                        {props.items?.map(elem =>
                                            <SingleElem
                                                key={elem.id}
                                                active={!!props.selectedItems?.find(elem2 => elem2.id === elem.id)}
                                            >
                                                <ShadowLayer onClick={(e) => selectShadowItem(elem, e)} />
                                                {(elem.data?.type === EProfileType.Personal || elem.data?.type === EProfileType.Company || elem.data?.type === EProfileType.Group || elem.data?.type === EProfileType.Cluster) &&
                                                    <Avatar
                                                        extended
                                                        firstName={elem.text}
                                                        lastName=""
                                                        type={elem.data?.type as EProfileType}
                                                        id={elem.id}
                                                        metaInfo={elem.data?.emailContact}
                                                        size={EAvatarSize.SMALL}
                                                        picture={elem.data.picture}
                                                        preventRedirection
                                                    />
                                                }
                                                {(elem.data?.type === 'default' || !elem.data.type) &&
                                                    <MultilineTextWithEllipsis numberOfLines={2}>
                                                        {elem.text}
                                                    </MultilineTextWithEllipsis>
                                                }
                                            </SingleElem>
                                        )}
                                    </>
                                ) : (
                                    <NoResult>
                                        <IntlMessage id="global.noResultsFound" />
                                    </NoResult>
                                )}
                            </div>
                        )}
                    </>
                }
            </Scrollbars>
            {!props.hideButtons &&
                <>
                    {(props.buttonsType === EButtonsTypes.Invite && props.inputText?.length > 3) &&
                        <AddElem>
                            <Icon>
                                <FontAwesomeIcon icon={faUser} />
                            </Icon>
                            <Texts>
                                <IntlMessage id="global.profileDoesntExist" tagName="span" />
                                <MultilineTextWithEllipsis numberOfLines={1}>{props.inputText}</MultilineTextWithEllipsis>
                            </Texts>
                            <Button typeSchema={EButtonTypeSchema.SECONDARY} onClick={props.userFormHandler}>
                                <IntlMessage id="global.invite" />
                            </Button>
                        </AddElem>
                    }
                    {(props.buttonsType === EButtonsTypes.Person && props.inputText?.length > 3) &&
                        <AddElem>
                            <Icon>
                                <FontAwesomeIcon icon={faUser} />
                            </Icon>
                            <Texts>
                                <IntlMessage id="global.profileDoesntExist" tagName="span" />
                                <MultilineTextWithEllipsis numberOfLines={1}>{props.inputText}</MultilineTextWithEllipsis>
                            </Texts>
                            <Button typeSchema={EButtonTypeSchema.SECONDARY} onClick={props.userFormHandler}>
                                <IntlMessage id="global.create" />
                            </Button>
                        </AddElem>
                    }
                    {(props.buttonsType === EButtonsTypes.Company && props.inputText?.length > 3) &&
                        <AddElem>
                            <Icon>
                                <FontAwesomeIcon icon={faBuilding} />
                            </Icon>
                            <Texts>
                                <IntlMessage id="global.leDoesntExist" tagName="span" />
                                <MultilineTextWithEllipsis numberOfLines={1}>{props.inputText}</MultilineTextWithEllipsis>
                            </Texts>
                            <Button typeSchema={EButtonTypeSchema.SECONDARY} onClick={props.companyFormHandler}>
                                <IntlMessage id="global.create" />
                            </Button>
                        </AddElem>
                    }
                    {(props.buttonsType === EButtonsTypes.Theme && props.themeFormHandler && props.inputText?.length > 0 && props.items.filter(item => item.text?.trim().toLocaleLowerCase() === props.inputText?.trim().toLocaleLowerCase()).length === 0) &&
                        <AddElem>
                            <Icon>
                                <FontAwesomeIcon icon={faBook} />
                            </Icon>
                            <Texts>
                                <IntlMessage id="global.themeDoesntExist" tagName="span" />
                                <span>{props.inputText}</span>
                            </Texts>
                            <Button typeSchema={EButtonTypeSchema.PRIMARY} onClick={props.themeFormHandler}>
                                <IntlMessage id="global.create" />
                            </Button>
                        </AddElem>
                    }
                    {(props.buttonsType === EButtonsTypes.AreaOfResponsibility && props.areaOfResponsibilityFormHandler && props.inputText?.length > 0 && props.items.filter(item => item.text?.trim().toLocaleLowerCase() === props.inputText?.trim().toLocaleLowerCase()).length === 0) &&
                        <AddElem>
                            <Icon>
                                <FontAwesomeIcon icon={faBook} />
                            </Icon>
                            <Texts>
                                <IntlMessage id="global.areaOfResponsibilityDoesntExist" tagName="span" />
                                <span>{props.inputText}</span>
                            </Texts>
                            <Button typeSchema={EButtonTypeSchema.PRIMARY} onClick={props.areaOfResponsibilityFormHandler}>
                                <IntlMessage id="global.create" />
                            </Button>
                        </AddElem>
                    }
                </>
            }
        </Wrapper>
    );
};
