import React, { FC, useState, useEffect, useRef, useMemo } from 'react';
import { ToolbarSettingsModel, QuickToolbarSettingsModel, RichTextEditorComponent, Toolbar, Inject, Image, HtmlEditor, Count, Link, QuickToolbar, Table, FontColorModel, BackgroundColorModel, FontSizeModel, FormatModel, PasteCleanup } from '@syncfusion/ej2-react-richtexteditor';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons';
import { MentionComponent } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { L10n } from '@syncfusion/ej2-base';

import './RT.css';
import { fontSize, fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { colorStack } from '../../../styleHelpers/colors';
import { Button, EButtonTypeSchema } from '../Buttons/NewButton';
import { Avatar } from '../Avatar/Avatar';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import { useDropdown, UseResize } from '../../../tools/hooks';
import { Attachment } from '../Attachment/Attachment';
import { EProfileType, ISingleFile } from '../../../entities/IGlobal';
import { checkAttachmentType } from '../../../tools/attachment';
import { EHintType, Hint } from '../Hint/Hint';
import { IState } from '../../../reducers';
import { IClusterReducer } from '../../../reducers/clustersReducer';
import { LabelWithEllipsis } from '../Label/Label';
import { MandatoryFieldStar } from '../MandatoryFieldStar/MandatoryFieldStar';
import IntlMessage, { useIntlMessage } from '../IntlMessage';
import { getUserPrefferedLanguage } from '../../../actions/languageActions';
import { languageList } from '../../../tools/languageTools';
import { ELcid } from '../../../entities/ILanguage';
import { ESpinnerSize, Spinner } from '../Spinner/Spinner';

const ButtonBox = styled.div`
    display: flex;
    background: ${colorStack.whiteGrey};
    padding: 1rem 1rem 0 1rem;
    justify-content: flex-end;
    border-radius: 0 0 6px 6px;
    padding: .5rem;
    > button {
        margin-left: .5rem;
    }
`;

const UploadFromComputer = styled.div`
    overflow: hidden;
    position: relative;
    color: ${colorStack.content};
    font-size: ${fontSize[16]};
    padding: .5rem;
    font-weight: 500;
    width: 100%;
    text-align: left;
    transition: all .3s;
    cursor: pointer;
    margin: 0!important;
    span {
        white-space: nowrap;
    }
    &:hover {
        background: ${colorStack.whiteBlue};
    }
    input[type="file"] {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }
`;

const Wrapper = styled.div`
    border: 2px solid ${colorStack.ligthGrey};
    border-radius: 8px;
    position: relative;
    .e-toolbar {
        background: ${colorStack.white};
        border-radius: 8px;
    }
    .e-toolbar .e-toolbar-item .e-tbar-btn:hover {
        background: ${colorStack.middleBlue};
        color: ${colorStack.white};
        border-radius: 4px;
    }
    .e-toolbar .e-toolbar-item .e-tbar-btn:hover .e-icons {
        color: ${colorStack.white};
    }
    .e-richtexteditor, .e-rte-content {
        border: none!important;
    }
    .e-richtexteditor .e-toolbar-wrapper {
        overflow: hidden;
        border-radius: 8px 8px 0 0;
        border-bottom: none;
    }
    .e-rte-content {
        background: ${colorStack.whiteGrey};
        border-radius: 0 0 8px 8px;
    }
    .e-richtexteditor .e-rte-toolbar {
        border-bottom: none!important;
    }
    .e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-rte-color-content:hover, .e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-rte-list-primary-content:hover, .e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-rte-color-content:hover, .e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-rte-list-primary-content:hover, .e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown:hover .e-rte-color-content:hover, .e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown:hover .e-rte-list-primary-content:hover, .e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown:hover .e-rte-color-content:hover, .e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown:hover .e-rte-list-primary-content:hover {
        background: ${colorStack.middleBlue};
    }
    .e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-icons {
        color: ${colorStack.darkGrey};
    }
    .e-toolbar .e-toolbar-items {
        background: ${colorStack.white};
    }
    .e-toolbar .e-toolbar-item .e-tbar-btn {
        background: ${colorStack.white};
    }
    .e-dlg-modal {
        width: 700px !important;
        .e-dlg-content .e-radio-wrapper {
            margin: 4px 0 4px 0;
            &.e-wrapper {
                margin: 0;
                label span.e-label {
                    padding-left: 24px !important;
                }
            }
        }
    }

    .e-richtexteditor .e-rte-content .e-content {
        h1 {
            ${fontSizeAndHeight[25]}
            font-weight: 400;
            padding: 0;
            color: ${colorStack.MSOfficeHeadingBlue};
            font-family: Arial;
            margin: 16px 0 8px 0;
        }
        h2 {
            ${fontSizeAndHeight[20]}
            font-weight: 400;

            span::after {
                content: none;
            }
            color: ${colorStack.MSOfficeHeadingBlue};
            font-family: Arial;
            margin: 16px 0 8px 0;
        }
        h3 {
            ${fontSizeAndHeight[16]}
            font-weight: 500;
            color: ${colorStack.MSOfficeHeadingBlue};
            font-family: Arial;
            margin: 16px 0 8px 0;
        }
        div {
            ${fontSizeAndHeight[13]}
            font-family: Arial;
        }
    }
    .e-content a {
        color: ${colorStack.middleBlue}!important;
        font-weight: 400;
        line-height: 1;
    }

    .e-content p {
        ${fontSizeAndHeight[13]}
        text-align: left;
        font-weight: 400;
        color: ${colorStack.content};
        font-family: Arial;
    }
    .e-content ol{
        ${fontSizeAndHeight[13]}
        list-style: decimal;
    }
    .e-content ul{
        ${fontSizeAndHeight[13]}
        list-style: disc;
    }
    .e-content ol, .e-content ul {
        padding-left: 3rem;
    }
`;

const MentionItem = styled.div`
    padding: 0.5rem;
    border-radius: 4px;
    &:hover {
        background: ${colorStack.ligthGrey};
    }
`;

const MentionElem = styled.span`
    a {
        color: ${colorStack.middleBlue}!important;
        font-weight: 600;
    }
`;

const AttachmentBox = styled.div`
    padding: 1rem;
    border-top: 2px solid ${colorStack.ligthGrey};
`;

let debounce: ReturnType<typeof setTimeout>;
let debounceHtml: ReturnType<typeof setTimeout>;

type GetUserPreferedLanguage = ReturnType<typeof getUserPrefferedLanguage>;

export interface IData {
    display: string;
    id: string;
    picture: string;
    type: EProfileType;
}

interface IProps {
    content: string;
    label?: string;
    required?: boolean;
    autofocus?: boolean;
    attachmentArray?: (ISingleFile | File)[];
    loading?: boolean;
    withoutClear?: boolean;
    allowEmptyMsg?: boolean;
    placeholder?: string;
    isPastePopupDisabled?: boolean;
    onChange(data: string);
    sendHandler?(plainText: string, newFilesArray: File[], files: ISingleFile[], tempValue?: string);
    setDisabled?(disabled: boolean);
    getMention?();
    onChangeGetMentionData?(elem: IData);
    setAttachmentArray?(files: File[]);
    openClusterDocPopup?();
}

const MENTION_POPUP_WIDTH = 300;
const MENTION_POPUP_MARGIN = 30;

export const NewRT: FC<React.PropsWithChildren<IProps>> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const dispatch = useDispatch();
    const { currentClusterData } = useSelector<IState, IClusterReducer>(state => ({ ...state.clusters }));
    const [locale, setLocale] = useState<string>(undefined);
    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown, openDropdown] = useDropdown();
    const [plainText, setPlainText] = useState<string>('');
    const [attachmentsArray, setAttachmentsArray] = useState<File[]>([]);
    const [currentFilesArray, setCurrentFilesArray] = useState<ISingleFile[]>([]);
    const [content, setContent] = useState<string>('');
    const [fileError, setFileError] = useState<boolean>(false);
    const [pasteDialogOpen, setPasteDialogOpen] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>();
    const rteObj = useRef<RichTextEditorComponent>(undefined);

    let dataSource = props.getMention?.() || undefined;

    const removeAll = () => {
        rteObj?.current?.updateValue('');
    };

    const getHTMLAndText = () => {
        clearTimeout(debounceHtml);
        debounceHtml = setTimeout(() => {
            props.onChange(rteObj?.current?.getText()?.length > 0 ? rteObj?.current?.getHtml() : '');
            setPlainText(rteObj?.current?.getText());
        }, 500);
    };

    useEffect(() => {
        if (props.attachmentArray?.length > 0) {
            setCurrentFilesArray(props.attachmentArray.filter((elem: ISingleFile) => elem.fileId) as ISingleFile[]);
            setAttachmentsArray(props.attachmentArray.filter((elem: ISingleFile) => !elem.fileId) as File[]);
        } else {
            setCurrentFilesArray([]);
            setAttachmentsArray([]);
        }
    }, [props.attachmentArray]);

    useEffect(() => {
        if (props.attachmentArray?.length > 0) {
            setCurrentFilesArray(props.attachmentArray.filter((elem: ISingleFile) => elem.fileId) as ISingleFile[]);
            setAttachmentsArray(props.attachmentArray.filter((elem: ISingleFile) => !elem.fileId) as File[]);
        }
        if (props.content) {
            setContent(props.content);
        }
    }, []);

    useEffect(() => {
        if (locale) {
            rteObj.current?.element.addEventListener('keyup', getHTMLAndText);
            rteObj.current?.element.addEventListener('paste', getHTMLAndText);
            rteObj.current?.element.addEventListener('click', getHTMLAndText);
        }
        return () => {
            if (locale) {
                rteObj.current?.element.removeEventListener('keyup', getHTMLAndText);
                rteObj.current?.element.removeEventListener('paste', getHTMLAndText);
                rteObj.current?.element.removeEventListener('click', getHTMLAndText);
            }
        };
    }, [locale]);

    useEffect(() => {
        if (props.content === undefined || props.content?.length === 0) {
            setContent('');
        }
    }, [props.content]);

    useEffect(() => {
        if (!props.loading && !props.withoutClear) {
            rteObj?.current?.updateValue('');
        }
    }, [props.loading]);

    const items = ['bold', 'italic', 'underline', 'strikeThrough', '|',
        'fontSize', '|', 'alignments', 'orderedList', 'unorderedList', '|', 'fontColor', 'backgroundColor', '|', 'clearFormat', 'formats', {
            template: `<button type="button" class="e-tbar-btn e-btn" tabindex="-1" id="custom_tbar"  style="width:100%"><div class="e-tbar-btn-text" style="font-weight: 500;"><span class="e-btn-icon e-clear-all e-icons"></span></div></button>`,
            undo: true,
            click: removeAll,
            tooltipText: intlFormatMessage({ id: 'global.remove' })
        }
    ];

    const toolbarSettings: ToolbarSettingsModel = {
        items: items
    };

    const pasteCleanupSettings = {
        deniedAttrs: ['align'],
        allowedStyleProperties: [
            'background',
            'background-color',
            'border',
            'border-bottom',
            'border-left',
            'border-radius',
            'border-right',
            'border-style',
            'border-top',
            'border-width',
            'clear',
            'color',
            'cursor',
            'direction',
            'display',
            'float',
            'font',
            'font-family',
            'font-size',
            'font-weight',
            'font-style',
            'height',
            'left',
            'line-height',
            'max-height',
            'max-width',
            'min-height',
            'min-width',
            'overflow',
            'overflow-x',
            'overflow-y',
            'position',
            'right',
            'table-layout',
            'text-align',
            'text-decoration',
            'text-indent',
            'top',
            'vertical-align',
            'visibility',
            'white-space',
            'width'
        ],
        plainText: props.isPastePopupDisabled,
        prompt: !props.isPastePopupDisabled
    };

    // since we have a dynamic translations mechanism, any language will have the same structure
    const getRTETranslations = (lang: string) => ({
        [lang]: {
            'richtexteditor': {
                'pasteFormat': intlFormatMessage({ id: 'global.rte.pasteFormat' }),
                'keepFormat': intlFormatMessage({ id: 'global.rte.keepFormat' }),
                'cleanFormat': intlFormatMessage({ id: 'global.rte.cleanFormat' }),
                'plainText': intlFormatMessage({ id: 'global.rte.plainText' })
            }
        }
    });

    const formatSettings: FormatModel = {
        width: 'auto',
        types: [
            { text: intlFormatMessage({ id: 'global.paragraph' }), value: 'P' },
            { text: 'H1', value: 'H1' },
            { text: 'H2', value: 'H2' },
            { text: 'H3', value: 'H3' }
        ]
    };

    const fontColorSettings: FontColorModel = {
        mode: 'Palette',
        colorCode: {
            'blue': ['#232C47', '#3C8BF8', '#68A8FF', '#CDE2FE', '#E4F0FF'],
            'green': ['#095107', '#008001', '#53A450', '#97D0A5', '#D4EDD9'],
            'orange': ['#B45917', '#FFB901', '#F3A83B', '#ECD081', '#F2E5C4'],
            'purple': ['#521776', '#9945B7', '#D689F1', '#EFC2FF', '#FCE8FF'],
            'red': ['#CA100E', '#FF0100', '#F18989', '#F5C6CA', '#FFE8EB'],
            'grey': ['#616161', '#858585', '#C5C5C5', '#E0E0E0', '#F5F5F5'],
            'other': ['#242424', '#424242', '#F0F0F0', '#fff', '#000']
        }
    };

    const backgroundColorSettings: BackgroundColorModel = {
        mode: 'Palette',
        colorCode: {
            'blue': ['#232C47', '#3C8BF8', '#68A8FF', '#CDE2FE', '#E4F0FF'],
            'green': ['#095107', '#008001', '#53A450', '#97D0A5', '#D4EDD9'],
            'orange': ['#B45917', '#FFB901', '#F3A83B', '#ECD081', '#F2E5C4'],
            'purple': ['#521776', '#9945B7', '#D689F1', '#EFC2FF', '#FCE8FF'],
            'red': ['#CA100E', '#FF0100', '#F18989', '#F5C6CA', '#FFE8EB'],
            'grey': ['#616161', '#858585', '#C5C5C5', '#E0E0E0', '#F5F5F5'],
            'other': ['#242424', '#424242', '#F0F0F0', '#fff', '#000']
        }
    };

    const fontSizeSettings: FontSizeModel = {
        width: '40px',
        default: fontSize[16],
        items: [
            { text: '10', value: fontSize[10] },
            { text: '13', value: fontSize[13] },
            { text: '16', value: fontSize[16] },
            { text: '20', value: fontSize[20] },
            { text: '25', value: fontSize[25] },
            { text: '31', value: fontSize[31] },
            { text: '39', value: fontSize[39] },
            { text: '49', value: fontSize[49] },
            { text: '61', value: fontSize[61] },
            { text: '76', value: fontSize[76] },
            { text: '95', value: fontSize[95] }
        ]
    };

    const quickToolbarSettings: QuickToolbarSettingsModel = {
        table: ['TableHeader', 'TableRows', 'TableColumns', 'TableCell', '-', 'BackgroundColor', 'TableRemove', 'TableCellVerticalAlign', 'Styles']
    };

    const sendHandler = () => {
        props.sendHandler(rteObj?.current?.getText(), attachmentsArray, currentFilesArray, rteObj?.current?.getHtml());
        if (!props.withoutClear) {
            removeAll();
            setPlainText(rteObj?.current?.getText());
            setContent('');
        }
    };

    let fieldsData = { text: 'display' };

    const noRecordsTemplate = `<div class="mention-component-norecord">${intlFormatMessage({ id: 'global.noRecord' })}</div>`;

    const itemTemplate = (data: IData) => {
        const getMentionData = () => {
            if (props.onChangeGetMentionData) {
                props.onChangeGetMentionData(data);
            }
        };

        return (
            <MentionItem onClick={getMentionData}>
                <Avatar
                    extended
                    picture={data.picture}
                    firstName={data.display}
                    type={data.type}
                    preventRedirection
                    lastName=""
                    id={data.id}
                />
            </MentionItem>
        );
    };

    const displayTemplate = (data: IData) => {
        return (
            <MentionElem>
                {data.type !== EProfileType.Cluster ? (
                    <RouterLink to={`/profile/${data.id}`} data-mention>{data.display}</RouterLink>
                ) : (
                    <RouterLink to={`/orgs/${currentClusterData.organizationUrlName}/cluster/${data.id}/activity`} data-mention>{data.display}</RouterLink>
                )
                }
            </MentionElem>
        );
    };

    useEffect(() => {
        if (props.autofocus && rteObj?.current) {
            setTimeout(() => {
                const elementToAutoFocus: any = rteObj?.current?.element?.querySelector('.e-content');
                elementToAutoFocus?.focus();
            }, 1000);
        }

        dispatch<GetUserPreferedLanguage>(getUserPrefferedLanguage()).then((lcid: ELcid) => {
            const lang = languageList?.[lcid];
            if (lang) {
                setLocale(lang);
                L10n.load(getRTETranslations(lang));
            }
        });
    }, []);

    const addAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
        const targetFiles = e.target.files;
        let arrayFiles = [];
        for (let i = 0; i < targetFiles.length; i++) {
            const attachType = checkAttachmentType(targetFiles[i].name.split('.')[targetFiles[i].name.split('.').length - 1], 'cluster');
            if (attachType.isValid) {
                arrayFiles.push(targetFiles[i]);
                setFileError(false);
            } else {
                setFileError(true);
            }
        }
        setAttachmentsArray(state => state ? [...state, ...arrayFiles] : arrayFiles);
        props.setAttachmentArray?.(props.attachmentArray ? [...props.attachmentArray, ...arrayFiles] : arrayFiles);
        toggleDropdown();
        getHTMLAndText();
    };

    const removeAttachment = (index: number, existing: boolean) => {
        if (existing) {
            setCurrentFilesArray(state => state.filter((attachment, i) => i !== index));
        } else {
            setAttachmentsArray(state => state.filter((attachment, i) => i !== index));
            props.setAttachmentArray?.(props.attachmentArray.filter((attachment, i) => i !== index) as File[]);
        }
    };

    const calculatePosition = () => {
        setTimeout(() => {
            const popup: HTMLElement = document.querySelector('#mention_integration_rte-edit-view_popup.e-popup-open');
            const mentionEditor: HTMLElement = document.querySelector('#mention_integration_rte-edit-view');
            if (popup?.style && window.innerWidth - mentionEditor.getBoundingClientRect().right + mentionEditor.getBoundingClientRect().width <= MENTION_POPUP_WIDTH + MENTION_POPUP_MARGIN) {
                popup.style.left = `${mentionEditor.getBoundingClientRect().left - MENTION_POPUP_WIDTH}px`;
            }
        }, 1);
    };

    const handleBeforeDialogOpen = () => {
        setPasteDialogOpen(true);
    };

    const handleBeforeDialogClose = () => {
        setPasteDialogOpen(false);
    };

    const sendButtonDisabled = useMemo(() => {
        const disabled = props.loading || pasteDialogOpen || (props.allowEmptyMsg ? false : plainText.replace(/[^\x20-\x7E]/gmi, '').trim().length === 0);

        props.setDisabled?.(disabled);

        return disabled;
    }, [props.loading, props.allowEmptyMsg, plainText, pasteDialogOpen]);

    return (
        <>
            {props.label &&
                <LabelWithEllipsis>
                    {props.label}
                    {props.required &&
                        <MandatoryFieldStar />
                    }
                </LabelWithEllipsis>
            }

            <Wrapper>
                {locale ?
                    <RichTextEditorComponent
                        locale={locale}
                        id={props.getMention ? 'mention_integration' : undefined}
                        ref={rteObj}
                        showCharCount={false}
                        placeholder={props.placeholder}
                        fontSize={fontSizeSettings}
                        showTooltip={false}
                        fontColor={fontColorSettings}
                        height={props.isPastePopupDisabled ? 220 : 400}
                        value={content}
                        backgroundColor={backgroundColorSettings}
                        toolbarSettings={toolbarSettings}
                        format={formatSettings}
                        quickToolbarSettings={quickToolbarSettings}
                        pasteCleanupSettings={pasteCleanupSettings}
                        beforeDialogOpen={props.isPastePopupDisabled ? undefined : handleBeforeDialogOpen}
                        beforeDialogClose={props.isPastePopupDisabled ? undefined : handleBeforeDialogClose}
                    >
                        <Inject services={[Toolbar, Image, Link, HtmlEditor, Count, QuickToolbar, Table, PasteCleanup]} />
                    </RichTextEditorComponent>
                    : <Spinner size={ESpinnerSize.LARGE} />
                }
                {(props.sendHandler || props.setAttachmentArray || props.openClusterDocPopup) &&
                    <ButtonBox ref={wrapperRef}>
                        {(props.setAttachmentArray || props.openClusterDocPopup) &&
                            <Button
                                onClick={openDropdown}
                                disabled={sendButtonDisabled}
                                typeSchema={EButtonTypeSchema.TERTIARY}
                            >
                                <FontAwesomeIcon icon={faPaperclip} />
                                {dropdownOpen &&
                                    <ContextMenu
                                        links={[{
                                            name: <IntlMessage id="clusterFilesystem.uploadFromMyComputer" />,
                                            customRender: <UploadFromComputer>
                                                <IntlMessage id="clusterFilesystem.uploadFromMyComputer" />
                                                <input
                                                    ref={inputRef}
                                                    type="file"
                                                    id="attachment-from-button"
                                                    multiple={true}
                                                    onChange={addAttachment}
                                                    disabled={sendButtonDisabled}
                                                    onClick={(event: any) => { event.currentTarget.value = undefined; }}
                                                />
                                            </UploadFromComputer>,
                                            visible: true
                                        }, {
                                            name: <IntlMessage id="clusterFilesystem.uploadFromMyCluster" />,
                                            action: props.openClusterDocPopup,
                                            visible: !!props.openClusterDocPopup
                                        }]}
                                        openFromRight
                                    />
                                }
                            </Button>
                        }
                        {props.sendHandler &&
                            <Button
                                disabled={sendButtonDisabled}
                                loading={props.loading}
                                onClick={props.sendHandler ? sendHandler : undefined}
                            >
                                <IntlMessage id="global.save" />
                            </Button>
                        }
                    </ButtonBox>
                }
                {(props.getMention && rteObj?.current) &&
                    <UseResize
                        onResize={() => {
                            calculatePosition();
                        }}
                    >
                        {() => (
                            <MentionComponent
                                id="mentionEditor"
                                target="#mention_integration_rte-edit-view"
                                suggestionCount={10}
                                allowSpaces
                                ignoreCase
                                highlight
                                query={new Query().take(10)}
                                noRecordsTemplate={noRecordsTemplate}
                                itemTemplate={itemTemplate}
                                displayTemplate={displayTemplate}
                                fields={fieldsData}
                                dataSource={dataSource}
                                popupWidth={`${MENTION_POPUP_WIDTH}px`}
                                popupHeight="200px"
                                opened={calculatePosition}
                            />
                        )}
                    </UseResize>
                }
                {(currentFilesArray?.length > 0 || attachmentsArray?.length > 0) &&
                    <AttachmentBox>
                        {(currentFilesArray ? [...currentFilesArray, ...attachmentsArray] : attachmentsArray).map((attachment, index) =>
                            <>
                                {attachment.name &&
                                    <Attachment
                                        key={index}
                                        fileName={attachment.name}
                                        visibleButtons
                                        canRemove
                                        hideCopyUrl
                                        hideDownload
                                        hideWopi
                                        hideView
                                        removeHandler={() => removeAttachment(index, !!(attachment as ISingleFile).fileId)}
                                    />
                                }
                            </>
                        )}
                    </AttachmentBox>
                }
            </Wrapper>

            {fileError &&
                <Hint type={EHintType.ERROR}><IntlMessage id="global.wrongFileType" /></Hint>
            }
        </>
    );
};
